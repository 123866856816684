import {React, useState} from 'react';
import {Dropdown, Segment, Input} from 'semantic-ui-react';

export const ChangeCompletionWeekDropdown = ({changeCompletionWeek, setChangeCompletionWeek}) => {
    return (
        <Dropdown
            placeholder='Change Completion Week?'
            value={changeCompletionWeek}
            onChange={(_, {value}) => setChangeCompletionWeek(value)}
            fluid
            selection
            options={BlankFixer(changeCompletionWeek, [
                {
                    key: '1',
                    value: true,
                    text: 'Yes',
                },
                {
                    key: '2',
                    value: false,
                    text: 'No',
                },
            ])}
        />
    );
};

export const TimelineWeeksDropdown = ({timelineWeeks, setTimelineWeeks}) => {
    return (
        <Dropdown
            placeholder='Select Manufacturing Weeks from Today'
            value={timelineWeeks}
            onChange={(_, {value}) => setTimelineWeeks(value)}
            fluid
            selection
            options={BlankFixer(timelineWeeks, [
                {
                    key: '1',
                    value: '1',
                    text: '1',
                },
                {
                    key: '2',
                    value: '2',
                    text: '2',
                },
                {
                    key: '3',
                    value: '3',
                    text: '3',
                },
                {
                    key: '4',
                    value: '4',
                    text: '4',
                },
                {
                    key: '5',
                    value: '5',
                    text: '5',
                },
                {
                    key: '6',
                    value: '6',
                    text: '6',
                },
                {
                    key: '7',
                    value: '7',
                    text: '7',
                },
                {
                    key: '8',
                    value: '8',
                    text: '8',
                },
                {
                    key: '9',
                    value: '9',
                    text: '9',
                },
                {
                    key: '10',
                    value: '10',
                    text: '10',
                },
                {
                    key: '11',
                    value: '11',
                    text: '11',
                },
                {
                    key: '12',
                    value: '12',
                    text: '12',
                },
                {
                    key: '13',
                    value: '13',
                    text: '13',
                },
                {
                    key: '14',
                    value: '14',
                    text: '14',
                },
                {
                    key: '15',
                    value: '15',
                    text: '15',
                },
                {
                    key: '16',
                    value: '16',
                    text: '16',
                },
                {
                    key: '17',
                    value: '17',
                    text: '17',
                },
                {
                    key: '18',
                    value: '18',
                    text: '18',
                },
                {
                    key: '19',
                    value: '19',
                    text: '19',
                },
                {
                    key: '20',
                    value: '20',
                    text: '20',
                },
                {
                    key: '21',
                    value: '21',
                    text: '21',
                },
                {
                    key: '22',
                    value: '22',
                    text: '22',
                },
                {
                    key: '23',
                    value: '23',
                    text: '23',
                },
                {
                    key: '24',
                    value: '24',
                    text: '24',
                },
                {
                    key: '25',
                    value: '25',
                    text: '25',
                },
                {
                    key: '26',
                    value: '26',
                    text: '26',
                },
            ])}
        />
    );
};


export const CompletionWeekDropdown = ({completionWeek, setCompletionWeek}) => {
    return (
        <Dropdown
            placeholder='Select Completion Week'
            value={completionWeek}
            onChange={(_, {value}) => setCompletionWeek(value)}
            fluid
            selection
            options={BlankFixer(completionWeek, [
                {
                    key: '1',
                    value: '1',
                    text: '1',
                },
                {
                    key: '2',
                    value: '2',
                    text: '2',
                },
                {
                    key: '3',
                    value: '3',
                    text: '3',
                },
                {
                    key: '4',
                    value: '4',
                    text: '4',
                },
                {
                    key: '5',
                    value: '5',
                    text: '5',
                },
                {
                    key: '6',
                    value: '6',
                    text: '6',
                },
                {
                    key: '7',
                    value: '7',
                    text: '7',
                },
                {
                    key: '8',
                    value: '8',
                    text: '8',
                },
                {
                    key: '9',
                    value: '9',
                    text: '9',
                },
                {
                    key: '10',
                    value: '10',
                    text: '10',
                },
                {
                    key: '11',
                    value: '11',
                    text: '11',
                },
                {
                    key: '12',
                    value: '12',
                    text: '12',
                },
                {
                    key: '13',
                    value: '13',
                    text: '13',
                },
                {
                    key: '14',
                    value: '14',
                    text: '14',
                },
                {
                    key: '15',
                    value: '15',
                    text: '15',
                },
                {
                    key: '16',
                    value: '16',
                    text: '16',
                },
                {
                    key: '17',
                    value: '17',
                    text: '17',
                },
                {
                    key: '18',
                    value: '18',
                    text: '18',
                },
                {
                    key: '19',
                    value: '19',
                    text: '19',
                },
                {
                    key: '20',
                    value: '20',
                    text: '20',
                },
                {
                    key: '21',
                    value: '21',
                    text: '21',
                },
                {
                    key: '22',
                    value: '22',
                    text: '22',
                },
                {
                    key: '23',
                    value: '23',
                    text: '23',
                },
                {
                    key: '24',
                    value: '24',
                    text: '24',
                },
                {
                    key: '25',
                    value: '25',
                    text: '25',
                },
                {
                    key: '26',
                    value: '26',
                    text: '26',
                },
                {
                    key: '27',
                    value: '27',
                    text: '27',
                },
                {
                    key: '28',
                    value: '28',
                    text: '28',
                },
                {
                    key: '29',
                    value: '29',
                    text: '29',
                },
                {
                    key: '30',
                    value: '30',
                    text: '30',
                },
                {
                    key: '31',
                    value: '31',
                    text: '31',
                },
                {
                    key: '32',
                    value: '32',
                    text: '32',
                },
                {
                    key: '33',
                    value: '33',
                    text: '33',
                },
                {
                    key: '34',
                    value: '34',
                    text: '34',
                },
                {
                    key: '35',
                    value: '35',
                    text: '35',
                },
                {
                    key: '36',
                    value: '36',
                    text: '36',
                },
                {
                    key: '37',
                    value: '37',
                    text: '37',
                },
                {
                    key: '38',
                    value: '38',
                    text: '38',
                },
                {
                    key: '39',
                    value: '39',
                    text: '39',
                },
                {
                    key: '40',
                    value: '40',
                    text: '40',
                },
                {
                    key: '41',
                    value: '41',
                    text: '41',
                },
                {
                    key: '42',
                    value: '42',
                    text: '42',
                },
                {
                    key: '43',
                    value: '43',
                    text: '43',
                },
                {
                    key: '44',
                    value: '44',
                    text: '44',
                },
                {
                    key: '45',
                    value: '45',
                    text: '45',
                },
                {
                    key: '46',
                    value: '46',
                    text: '46',
                },
                {
                    key: '47',
                    value: '47',
                    text: '47',
                },
                {
                    key: '48',
                    value: '48',
                    text: '48',
                },
                {
                    key: '49',
                    value: '49',
                    text: '49',
                },
                {
                    key: '50',
                    value: '50',
                    text: '50',
                },
                {
                    key: '51',
                    value: '51',
                    text: '51',
                },
                {
                    key: '52',
                    value: '52',
                    text: '52',
                },
            ])}
        />
    );
};

const toeOptions = [
    {
        key: 'Regular',
        value: 'Regular',
        text: 'Regular',
    },
    {
        key: 'Celastic stiff toe',
        value: 'Celastic stiff toe',
        text: 'Celastic stiff toe',
    },
    {
        key: 'Leather Toe Cap',
        value: 'Leather Toe Cap',
        text: 'Leather Toe Cap',
    },
    {
        key: 'Safety Toe',
        value: 'Safety Toe',
        text: 'Safety Toe',
    },
    {
        key: 'Composite',
        value: 'Composite',
        text: 'Composite',
    },
    {
        key: 'Steel Toe',
        value: 'Steel Toe',
        text: 'Steel Toe',
    },
    {
        key: 'Select toe type',
        value: '',
        text: 'Select toe type',
    },
];

export const LiftLeftFootDropdown = ({liftLeftFoot, setLiftLeftFoot}) => {
    return (
        <Dropdown
            placeholder='Select lift for left foot'
            value={liftLeftFoot}
            onChange={(_, {value}) => setLiftLeftFoot(value)}
            fluid
            selection
            options={BlankFixer(liftLeftFoot, [
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                {
                    key: '0.25"',
                    value: '0.25"',
                    text: '0.25"',
                },
                {
                    key: '0.5"',
                    value: '0.5"',
                    text: '0.5"',
                },
                {
                    key: '0.75"',
                    value: '0.75"',
                    text: '0.75"',
                },
                {
                    key: '1"',
                    value: '1"',
                    text: '1"',
                },
                {
                    key: '1.25"',
                    value: '1.25"',
                    text: '1.25"',
                },
                {
                    key: '1.5"',
                    value: '1.5"',
                    text: '1.5"',
                },
            ])}
        />
    );
};

export const LiftRightFootDropdown = ({liftRightFoot, setLiftRightFoot}) => {
    return (
        <Dropdown
            placeholder='Select lift for right foot'
            value={liftRightFoot}
            onChange={(_, {value}) => setLiftRightFoot(value)}
            fluid
            selection
            options={BlankFixer(liftRightFoot, [
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                {
                    key: '0.25"',
                    value: '0.25"',
                    text: '0.25"',
                },
                {
                    key: '0.5"',
                    value: '0.5"',
                    text: '0.5"',
                },
                {
                    key: '0.75"',
                    value: '0.75"',
                    text: '0.75"',
                },
                {
                    key: '1"',
                    value: '1"',
                    text: '1"',
                },
                {
                    key: '1.25"',
                    value: '1.25"',
                    text: '1.25"',
                },
                {
                    key: '1.5"',
                    value: '1.5"',
                    text: '1.5"',
                },
            ])}
        />
    );
};

export const SideDropdown = ({side, setSide}) => {
    return (
        <Dropdown
            placeholder='Select side'
            value={side}
            onChange={(_, {value}) => setSide(value)}
            fluid
            selection
            options={BlankFixer(side, [
                {
                    key: 'Full Rough',
                    value: 'Full Rough',
                    text: 'Full Rough',
                },
                {
                    key: 'Full Smooth',
                    value: 'Full Smooth',
                    text: 'Full Smooth',
                },
                {
                    key: 'Set side dropdown',
                    value: '',
                    text: 'Set side dropdown',
                },
            ])}
        />
    );
};

export const HeelLiftDropdown = ({heelLift, setHeelLift}) => {
    return (
        <Dropdown
            placeholder='Select heel lift'
            value={heelLift}
            onChange={(_, {value}) => setHeelLift(value)}
            fluid
            selection
            options={BlankFixer(heelLift, [
                {
                    key: '1-Lift',
                    value: '1-Lift',
                    text: '1-Lift',
                },
                {
                    key: '2-Lift',
                    value: '2-Lift',
                    text: '2-Lift',
                },
                {
                    key: '3-Lift',
                    value: '3-Lift',
                    text: '3-Lift',
                },
                {
                    key: '4-Lift',
                    value: '4-Lift',
                    text: '4-Lift',
                },
                {
                    key: '5-Lift',
                    value: '5-Lift',
                    text: '5-Lift',
                },
                {
                    key: '6-Lift',
                    value: '6-Lift',
                    text: '6-Lift',
                },
                {
                    key: 'ZERO DROP',
                    value: 'ZERO DROP',
                    text: 'ZERO DROP',
                },
                
            ])}
        />
    );
};

export const HeelPosition = ({heelPosition, setHeelPosition}) => {
    return (
        <Dropdown
            placeholder='Select heel position'
            value={heelPosition}
            onChange={(_, {value}) => setHeelPosition(value)}
            fluid
            selection
            options={BlankFixer(heelPosition, [
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'Heels Click In',
                    value: 'Heels Click In',
                    text: 'Heels Click In',
                },
                {
                    key: 'Heels Click Out',
                    value: 'Heels Click Out',
                    text: 'Heels Click Out',
                },
            ])}
        />
    );
};

export const HeelShapeDropdown = ({heelShape, setHeelShape}) => {
    return (
        <Dropdown
            placeholder='Select heel shape'
            value={heelShape}
            onChange={(_, {value}) => setHeelShape(value)}
            fluid
            selection
            options={BlankFixer(heelShape, [
                {
                    key: 'Packer',
                    value: 'Packer',
                    text: 'Packer',
                },
                {
                    key: 'Block',
                    value: 'Block',
                    text: 'Block',
                },
                {
                    key: 'Logger',
                    value: 'Logger',
                    text: 'Logger',
                },
                {
                    key: 'Spring',
                    value: 'Spring',
                    text: 'Spring',
                },
                {
                    key: 'Dogger',
                    value: 'Dogger',
                    text: 'Dogger',
                },       
            ])}
        />
    );
};

export const StitchingRowsDropdown = ({stitchingRows, setStitchingRows}) => {
    return (
        <Dropdown
            placeholder='Select stitching rows'
            value={stitchingRows}
            onChange={(_, {value}) => setStitchingRows(value)}
            fluid
            selection
            options={BlankFixer(stitchingRows, [
                {
                    key: 'One Row',
                    value: 'One Row',
                    text: 'One Row',
                },
                {
                    key: 'Two Rows',
                    value: 'Two Rows',
                    text: 'Two Rows',
                },
            ])}
        />
    );
};

export const EyeletRepairDropdown = ({eyeletRepair, setEyeletRepair}) => {
    return (
        <Dropdown
            placeholder='Select Eyelet Repair'
            value={eyeletRepair}
            onChange={(_, {value}) => setEyeletRepair(value)}
            fluid
            selection
            options={BlankFixer(eyeletRepair, [
                {
                    key: 'Replace All Eyelets',
                    value: 'Replace All Eyelets',
                    text: 'Replace All Eyelets',
                },
            ])}
        />
    );
};

export const PatchRepairDropdown = ({patchRepair, setPatchRepair}) => {
    return (
        <Dropdown
            placeholder='Select Eyelet Repair'
            value={patchRepair}
            onChange={(_, {value}) => setPatchRepair(value)}
            fluid
            selection
            options={BlankFixer(patchRepair, [
                {
                    key: 'Patch Hole Up To 1" in Diameter',
                    value: 'Patch Hole Up To 1" in Diameter',
                    text: 'Patch Hole Up To 1" in Diameter',
                },
                {
                    key: 'Patch Hole Over 1" in Diamater',
                    value: 'Patch Hole Over 1" in Diamater',
                    text: 'Patch Hole Over 1" in Diamater',
                },
            ])}
        />
    );
};

export const QuartersDropdown = ({quarters, setQuarters}) => {
    return (
        <Dropdown
            placeholder='Select quarters'
            value={quarters}
            onChange={(_, {value}) => setQuarters(value)}
            fluid
            selection
            options={BlankFixer(quarters, [
                {
                    key: '3 E',
                    value: '3 E',
                    text: '3 E',
                },
                {
                    key: '3 EE',
                    value: '3 EE',
                    text: '3 EE',
                },
                {
                    key: '3 3E',
                    value: '3 3E',
                    text: '3 3E',
                },
                {
                    key: '3 4E',
                    value: '3 4E',
                    text: '3 4E',
                },
                {
                    key: '3 5E',
                    value: '3 5E',
                    text: '3 5E',
                },
                {
                    key: '3 6E',
                    value: '3 6E',
                    text: '3 6E',
                },
                {
                    key: '3 7E',
                    value: '3 7E',
                    text: '3 7E',
                },
                {
                    key: '3 8E',
                    value: '3 8E',
                    text: '3 8E',
                },
                {
                    key: '3.5 E',
                    value: '3.5 E',
                    text: '3.5 E',
                },
                {
                    key: '3.5 EE',
                    value: '3.5 EE',
                    text: '3.5 EE',
                },
                {
                    key: '3.5 3E',
                    value: '3.5 3E',
                    text: '3.5 3E',
                },
                {
                    key: '3.5 4E',
                    value: '3.5 4E',
                    text: '3.5 4E',
                },
                {
                    key: '3.5 5E',
                    value: '3.5 5E',
                    text: '3.5 5E',
                },
                {
                    key: '3.5 6E',
                    value: '3.5 6E',
                    text: '3.5 6E',
                },
                {
                    key: '3.5 7E',
                    value: '3.5 7E',
                    text: '3.5 7E',
                },
                {
                    key: '3.5 8E',
                    value: '3.5 8E',
                    text: '3.5 8E',
                },
                {
                    key: '4 E',
                    value: '4 E',
                    text: '4 E',
                },
                {
                    key: '4 EE',
                    value: '4 EE',
                    text: '4 EE',
                },
                {
                    key: '4 3E',
                    value: '4 3E',
                    text: '4 3E',
                },
                {
                    key: '4 4E',
                    value: '4 4E',
                    text: '4 4E',
                },
                {
                    key: '4 5E',
                    value: '4 5E',
                    text: '4 5E',
                },
                {
                    key: '4 6E',
                    value: '4 6E',
                    text: '4 6E',
                },
                {
                    key: '4 7E',
                    value: '4 7E',
                    text: '4 7E',
                },
                {
                    key: '4 8E',
                    value: '4 8E',
                    text: '4 8E',
                },
                {
                    key: '4.5 E',
                    value: '4.5 E',
                    text: '4.5 E',
                },
                {
                    key: '4.5 EE',
                    value: '4.5 EE',
                    text: '4.5 EE',
                },
                {
                    key: '4.5 3E',
                    value: '4.5 3E',
                    text: '4.5 3E',
                },
                {
                    key: '4.5 4E',
                    value: '4.5 4E',
                    text: '4.5 4E',
                },
                {
                    key: '4.5 5E',
                    value: '4.5 5E',
                    text: '4.5 5E',
                },
                {
                    key: '4.5 6E',
                    value: '4.5 6E',
                    text: '4.5 6E',
                },
                {
                    key: '4.5 7E',
                    value: '4.5 7E',
                    text: '4.5 7E',
                },
                {
                    key: '4.5 8E',
                    value: '4.5 8E',
                    text: '4.5 8E',
                },
                {
                    key: '5 E',
                    value: '5 E',
                    text: '5 E',
                },
                {
                    key: '5 EE',
                    value: '5 EE',
                    text: '5 EE',
                },
                {
                    key: '5 3E',
                    value: '5 3E',
                    text: '5 3E',
                },
                {
                    key: '5 4E',
                    value: '5 4E',
                    text: '5 4E',
                },
                {
                    key: '5 5E',
                    value: '5 5E',
                    text: '5 5E',
                },
                {
                    key: '5 6E',
                    value: '5 6E',
                    text: '5 6E',
                },
                {
                    key: '5 7E',
                    value: '5 7E',
                    text: '5 7E',
                },
                {
                    key: '5 8E',
                    value: '5 8E',
                    text: '5 8E',
                },
                {
                    key: '5.5 E',
                    value: '5.5 E',
                    text: '5.5 E',
                },
                {
                    key: '5.5 EE',
                    value: '5.5 EE',
                    text: '5.5 EE',
                },
                {
                    key: '5.5 3E',
                    value: '5.5 3E',
                    text: '5.5 3E',
                },
                {
                    key: '5.5 4E',
                    value: '5.5 4E',
                    text: '5.5 4E',
                },
                {
                    key: '5.5 5E',
                    value: '5.5 5E',
                    text: '5.5 5E',
                },
                {
                    key: '5.5 6E',
                    value: '5.5 6E',
                    text: '5.5 6E',
                },
                {
                    key: '5.5 7E',
                    value: '5.5 7E',
                    text: '5.5 7E',
                },
                {
                    key: '5.5 8E',
                    value: '5.5 8E',
                    text: '5.5 8E',
                },
                {
                    key: '6 E',
                    value: '6 E',
                    text: '6 E',
                },
                {
                    key: '6 EE',
                    value: '6 EE',
                    text: '6 EE',
                },
                {
                    key: '6 3E',
                    value: '6 3E',
                    text: '6 3E',
                },
                {
                    key: '6 4E',
                    value: '6 4E',
                    text: '6 4E',
                },
                {
                    key: '6 5E',
                    value: '6 5E',
                    text: '6 5E',
                },
                {
                    key: '6 6E',
                    value: '6 6E',
                    text: '6 6E',
                },
                {
                    key: '6 7E',
                    value: '6 7E',
                    text: '6 7E',
                },
                {
                    key: '6 8E',
                    value: '6 8E',
                    text: '6 8E',
                },
                {
                    key: '6.5 E',
                    value: '6.5 E',
                    text: '6.5 E',
                },
                {
                    key: '6.5 EE',
                    value: '6.5 EE',
                    text: '6.5 EE',
                },
                {
                    key: '6.5 3E',
                    value: '6.5 3E',
                    text: '6.5 3E',
                },
                {
                    key: '6.5 4E',
                    value: '6.5 4E',
                    text: '6.5 4E',
                },
                {
                    key: '6.5 5E',
                    value: '6.5 5E',
                    text: '6.5 5E',
                },
                {
                    key: '6.5 6E',
                    value: '6.5 6E',
                    text: '6.5 6E',
                },
                {
                    key: '6.5 7E',
                    value: '6.5 7E',
                    text: '6.5 7E',
                },
                {
                    key: '6.5 8E',
                    value: '6.5 8E',
                    text: '6.5 8E',
                },
                {
                    key: '7 E',
                    value: '7 E',
                    text: '7 E',
                },
                {
                    key: '7 EE',
                    value: '7 EE',
                    text: '7 EE',
                },
                {
                    key: '7 3E',
                    value: '7 3E',
                    text: '7 3E',
                },
                {
                    key: '7 4E',
                    value: '7 4E',
                    text: '7 4E',
                },
                {
                    key: '7 5E',
                    value: '7 5E',
                    text: '7 5E',
                },
                {
                    key: '7 6E',
                    value: '7 6E',
                    text: '7 6E',
                },
                {
                    key: '7 7E',
                    value: '7 7E',
                    text: '7 7E',
                },
                {
                    key: '7 8E',
                    value: '7 8E',
                    text: '7 8E',
                },
                {
                    key: '7.5 E',
                    value: '7.5 E',
                    text: '7.5 E',
                },
                {
                    key: '7.5 EE',
                    value: '7.5 EE',
                    text: '7.5 EE',
                },
                {
                    key: '7.5 3E',
                    value: '7.5 3E',
                    text: '7.5 3E',
                },
                {
                    key: '7.5 4E',
                    value: '7.5 4E',
                    text: '7.5 4E',
                },
                {
                    key: '7.5 5E',
                    value: '7.5 5E',
                    text: '7.5 5E',
                },
                {
                    key: '7.5 6E',
                    value: '7.5 6E',
                    text: '7.5 6E',
                },
                {
                    key: '7.5 7E',
                    value: '7.5 7E',
                    text: '7.5 7E',
                },
                {
                    key: '7.5 8E',
                    value: '7.5 8E',
                    text: '7.5 8E',
                },
                {
                    key: '8 E',
                    value: '8 E',
                    text: '8 E',
                },
                {
                    key: '8 EE',
                    value: '8 EE',
                    text: '8 EE',
                },
                {
                    key: '8 3E',
                    value: '8 3E',
                    text: '8 3E',
                },
                {
                    key: '8 4E',
                    value: '8 4E',
                    text: '8 4E',
                },
                {
                    key: '8 5E',
                    value: '8 5E',
                    text: '8 5E',
                },
                {
                    key: '8 6E',
                    value: '8 6E',
                    text: '8 6E',
                },
                {
                    key: '8 7E',
                    value: '8 7E',
                    text: '8 7E',
                },
                {
                    key: '8 8E',
                    value: '8 8E',
                    text: '8 8E',
                },
                {
                    key: '8.5 E',
                    value: '8.5 E',
                    text: '8.5 E',
                },
                {
                    key: '8.5 EE',
                    value: '8.5 EE',
                    text: '8.5 EE',
                },
                {
                    key: '8.5 3E',
                    value: '8.5 3E',
                    text: '8.5 3E',
                },
                {
                    key: '8.5 4E',
                    value: '8.5 4E',
                    text: '8.5 4E',
                },
                {
                    key: '8.5 5E',
                    value: '8.5 5E',
                    text: '8.5 5E',
                },
                {
                    key: '8.5 6E',
                    value: '8.5 6E',
                    text: '8.5 6E',
                },
                {
                    key: '8.5 7E',
                    value: '8.5 7E',
                    text: '8.5 7E',
                },
                {
                    key: '8.5 8E',
                    value: '8.5 8E',
                    text: '8.5 8E',
                },
                {
                    key: '9 E',
                    value: '9 E',
                    text: '9 E',
                },
                {
                    key: '9 EE',
                    value: '9 EE',
                    text: '9 EE',
                },
                {
                    key: '9 3E',
                    value: '9 3E',
                    text: '9 3E',
                },
                {
                    key: '9 4E',
                    value: '9 4E',
                    text: '9 4E',
                },
                {
                    key: '9 5E',
                    value: '9 5E',
                    text: '9 5E',
                },
                {
                    key: '9 6E',
                    value: '9 6E',
                    text: '9 6E',
                },
                {
                    key: '9 7E',
                    value: '9 7E',
                    text: '9 7E',
                },
                {
                    key: '9 8E',
                    value: '9 8E',
                    text: '9 8E',
                },
                {
                    key: '9.5 E',
                    value: '9.5 E',
                    text: '9.5 E',
                },
                {
                    key: '9.5 EE',
                    value: '9.5 EE',
                    text: '9.5 EE',
                },
                {
                    key: '9.5 3E',
                    value: '9.5 3E',
                    text: '9.5 3E',
                },
                {
                    key: '9.5 4E',
                    value: '9.5 4E',
                    text: '9.5 4E',
                },
                {
                    key: '9.5 5E',
                    value: '9.5 5E',
                    text: '9.5 5E',
                },
                {
                    key: '9.5 6E',
                    value: '9.5 6E',
                    text: '9.5 6E',
                },
                {
                    key: '9.5 7E',
                    value: '9.5 7E',
                    text: '9.5 7E',
                },
                {
                    key: '9.5 8E',
                    value: '9.5 8E',
                    text: '9.5 8E',
                },
                {
                    key: '10 E',
                    value: '10 E',
                    text: '10 E',
                },
                {
                    key: '10 EE',
                    value: '10 EE',
                    text: '10 EE',
                },
                {
                    key: '10 3E',
                    value: '10 3E',
                    text: '10 3E',
                },
                {
                    key: '10 4E',
                    value: '10 4E',
                    text: '10 4E',
                },
                {
                    key: '10 5E',
                    value: '10 5E',
                    text: '10 5E',
                },
                {
                    key: '10 6E',
                    value: '10 6E',
                    text: '10 6E',
                },
                {
                    key: '10 7E',
                    value: '10 7E',
                    text: '10 7E',
                },
                {
                    key: '10 8E',
                    value: '10 8E',
                    text: '10 8E',
                },
                {
                    key: '10.5 E',
                    value: '10.5 E',
                    text: '10.5 E',
                },
                {
                    key: '10.5 EE',
                    value: '10.5 EE',
                    text: '10.5 EE',
                },
                {
                    key: '10.5 3E',
                    value: '10.5 3E',
                    text: '10.5 3E',
                },
                {
                    key: '10.5 4E',
                    value: '10.5 4E',
                    text: '10.5 4E',
                },
                {
                    key: '10.5 5E',
                    value: '10.5 5E',
                    text: '10.5 5E',
                },
                {
                    key: '10.5 6E',
                    value: '10.5 6E',
                    text: '10.5 6E',
                },
                {
                    key: '10.5 7E',
                    value: '10.5 7E',
                    text: '10.5 7E',
                },
                {
                    key: '10.5 8E',
                    value: '10.5 8E',
                    text: '10.5 8E',
                },
                {
                    key: '11 E',
                    value: '11 E',
                    text: '11 E',
                },
                {
                    key: '11 EE',
                    value: '11 EE',
                    text: '11 EE',
                },
                {
                    key: '11 3E',
                    value: '11 3E',
                    text: '11 3E',
                },
                {
                    key: '11 4E',
                    value: '11 4E',
                    text: '11 4E',
                },
                {
                    key: '11 5E',
                    value: '11 5E',
                    text: '11 5E',
                },
                {
                    key: '11 6E',
                    value: '11 6E',
                    text: '11 6E',
                },
                {
                    key: '11 7E',
                    value: '11 7E',
                    text: '11 7E',
                },
                {
                    key: '11 8E',
                    value: '11 8E',
                    text: '11 8E',
                },
                {
                    key: '11.5 E',
                    value: '11.5 E',
                    text: '11.5 E',
                },
                {
                    key: '11.5 EE',
                    value: '11.5 EE',
                    text: '11.5 EE',
                },
                {
                    key: '11.5 3E',
                    value: '11.5 3E',
                    text: '11.5 3E',
                },
                {
                    key: '11.5 4E',
                    value: '11.5 4E',
                    text: '11.5 4E',
                },
                {
                    key: '11.5 5E',
                    value: '11.5 5E',
                    text: '11.5 5E',
                },
                {
                    key: '11.5 6E',
                    value: '11.5 6E',
                    text: '11.5 6E',
                },
                {
                    key: '11.5 7E',
                    value: '11.5 7E',
                    text: '11.5 7E',
                },
                {
                    key: '11.5 8E',
                    value: '11.5 8E',
                    text: '11.5 8E',
                },
                {
                    key: '12 E',
                    value: '12 E',
                    text: '12 E',
                },
                {
                    key: '12 EE',
                    value: '12 EE',
                    text: '12 EE',
                },
                {
                    key: '12 3E',
                    value: '12 3E',
                    text: '12 3E',
                },
                {
                    key: '12 4E',
                    value: '12 4E',
                    text: '12 4E',
                },
                {
                    key: '12 5E',
                    value: '12 5E',
                    text: '12 5E',
                },
                {
                    key: '12 6E',
                    value: '12 6E',
                    text: '12 6E',
                },
                {
                    key: '12 7E',
                    value: '12 7E',
                    text: '12 7E',
                },
                {
                    key: '12 8E',
                    value: '12 8E',
                    text: '12 8E',
                },
                {
                    key: '12.5 E',
                    value: '12.5 E',
                    text: '12.5 E',
                },
                {
                    key: '12.5 EE',
                    value: '12.5 EE',
                    text: '12.5 EE',
                },
                {
                    key: '12.5 3E',
                    value: '12.5 3E',
                    text: '12.5 3E',
                },
                {
                    key: '12.5 4E',
                    value: '12.5 4E',
                    text: '12.5 4E',
                },
                {
                    key: '12.5 5E',
                    value: '12.5 5E',
                    text: '12.5 5E',
                },
                {
                    key: '12.5 6E',
                    value: '12.5 6E',
                    text: '12.5 6E',
                },
                {
                    key: '12.5 7E',
                    value: '12.5 7E',
                    text: '12.5 7E',
                },
                {
                    key: '12.5 8E',
                    value: '12.5 8E',
                    text: '12.5 8E',
                },
                {
                    key: '13 E',
                    value: '13 E',
                    text: '13 E',
                },
                {
                    key: '13 EE',
                    value: '13 EE',
                    text: '13 EE',
                },
                {
                    key: '13 3E',
                    value: '13 3E',
                    text: '13 3E',
                },
                {
                    key: '13 4E',
                    value: '13 4E',
                    text: '13 4E',
                },
                {
                    key: '13 5E',
                    value: '13 5E',
                    text: '13 5E',
                },
                {
                    key: '13 6E',
                    value: '13 6E',
                    text: '13 6E',
                },
                {
                    key: '13 7E',
                    value: '13 7E',
                    text: '13 7E',
                },
                {
                    key: '13 8E',
                    value: '13 8E',
                    text: '13 8E',
                },
                {
                    key: '13.5 E',
                    value: '13.5 E',
                    text: '13.5 E',
                },
                {
                    key: '13.5 EE',
                    value: '13.5 EE',
                    text: '13.5 EE',
                },
                {
                    key: '13.5 3E',
                    value: '13.5 3E',
                    text: '13.5 3E',
                },
                {
                    key: '13.5 4E',
                    value: '13.5 4E',
                    text: '13.5 4E',
                },
                {
                    key: '13.5 5E',
                    value: '13.5 5E',
                    text: '13.5 5E',
                },
                {
                    key: '13.5 6E',
                    value: '13.5 6E',
                    text: '13.5 6E',
                },
                {
                    key: '13.5 7E',
                    value: '13.5 7E',
                    text: '13.5 7E',
                },
                {
                    key: '13.5 8E',
                    value: '13.5 8E',
                    text: '13.5 8E',
                },
                {
                    key: '14 E',
                    value: '14 E',
                    text: '14 E',
                },
                {
                    key: '14 EE',
                    value: '14 EE',
                    text: '14 EE',
                },
                {
                    key: '14 3E',
                    value: '14 3E',
                    text: '14 3E',
                },
                {
                    key: '14 4E',
                    value: '14 4E',
                    text: '14 4E',
                },
                {
                    key: '14 5E',
                    value: '14 5E',
                    text: '14 5E',
                },
                {
                    key: '14 6E',
                    value: '14 6E',
                    text: '14 6E',
                },
                {
                    key: '14 7E',
                    value: '14 7E',
                    text: '14 7E',
                },
                {
                    key: '14 8E',
                    value: '14 8E',
                    text: '14 8E',
                },
                {
                    key: '14.5 E',
                    value: '14.5 E',
                    text: '14.5 E',
                },
                {
                    key: '14.5 EE',
                    value: '14.5 EE',
                    text: '14.5 EE',
                },
                {
                    key: '14.5 3E',
                    value: '14.5 3E',
                    text: '14.5 3E',
                },
                {
                    key: '14.5 4E',
                    value: '14.5 4E',
                    text: '14.5 4E',
                },
                {
                    key: '14.5 5E',
                    value: '14.5 5E',
                    text: '14.5 5E',
                },
                {
                    key: '14.5 6E',
                    value: '14.5 6E',
                    text: '14.5 6E',
                },
                {
                    key: '14.5 7E',
                    value: '14.5 7E',
                    text: '14.5 7E',
                },
                {
                    key: '14.5 8E',
                    value: '14.5 8E',
                    text: '14.5 8E',
                },
                {
                    key: '15 E',
                    value: '15 E',
                    text: '15 E',
                },
                {
                    key: '15 EE',
                    value: '15 EE',
                    text: '15 EE',
                },
                {
                    key: '15 3E',
                    value: '15 3E',
                    text: '15 3E',
                },
                {
                    key: '15 4E',
                    value: '15 4E',
                    text: '15 4E',
                },
                {
                    key: '15 5E',
                    value: '15 5E',
                    text: '15 5E',
                },
                {
                    key: '15 6E',
                    value: '15 6E',
                    text: '15 6E',
                },
                {
                    key: '15 7E',
                    value: '15 7E',
                    text: '15 7E',
                },
                {
                    key: '15 8E',
                    value: '15 8E',
                    text: '15 8E',
                },
                
            ])}
        />
    );
};

export const SimpleSideDropdown = ({side, setSide}) => {
    return (
        <Dropdown
            placeholder='Select side'
            value={side}
            onChange={(_, {value}) => setSide(value)}
            fluid
            selection
            options={BlankFixer(side, [
                {
                    key: 'RGH',
                    value: 'RGH',
                    text: 'Rough',
                },
                {
                    key: 'SM',
                    value: 'SM',
                    text: 'Smooth',
                },
            ])}
        />
    );
};

export const BeltSizeDropdown = ({beltSize, setBeltSize}) => {
    return (
        <Dropdown
            placeholder='Select Belt Size'
            value={beltSize}
            onChange={(_, {value}) => setBeltSize(value)}
            fluid
            selection
            options={BlankFixer(beltSize, [
                {
                    key: '32',
                    value: '32',
                    text: '32',
                },
                {
                    key: '34',
                    value: '34',
                    text: '34',
                },
                {
                    key: '36',
                    value: '36',
                    text: '36',
                },
                {
                    key: '38',
                    value: '38',
                    text: '38',
                },
                {
                    key: '40',
                    value: '40',
                    text: '40',
                },
                {
                    key: '42',
                    value: '42',
                    text: '42',
                },
                {
                    key: '44',
                    value: '44',
                    text: '44',
                },
                {
                    key: '46',
                    value: '46',
                    text: '46',
                },
                {
                    key: '48',
                    value: '48',
                    text: '48',
                },
                {
                    key: '50',
                    value: '50',
                    text: '50',
                },
                {
                    key: '52',
                    value: '52',
                    text: '52',
                },
                {
                    key: '54',
                    value: '54',
                    text: '54',
                },
                {
                    key: '56',
                    value: '56',
                    text: '56',
                },
                {
                    key: '58',
                    value: '58',
                    text: '58',
                },
                {
                    key: '60',
                    value: '60',
                    text: '60',
                },                
            ])}
        />
    );
};

export const StitchColorDropdown = ({color, setColor}) => {
    return (
        <Dropdown
            placeholder='Select color'
            value={color}
            onChange={(_, {value}) => setColor(value)}
            fluid
            selection
            options={BlankFixer(color, [
                {
                    key: 'Black (Technora)',
                    value: 'Black (Technora)',
                    text: 'Black',
                },
                {
                    key: 'Beige',
                    value: 'Beige',
                    text: 'Beige',
                },
            ])}
        />
    );
};

export const ShortenedSideDropdown = ({side, setSide}) => {
    return (
        <Dropdown
            placeholder='Select side'
            value={side}
            onChange={(_, {value}) => setSide(value)}
            fluid
            selection
            options={BlankFixer(side, [
                {
                    key: 'RGH',
                    value: 'RGH',
                    text: 'Rough',
                },
                {
                    key: 'SM',
                    value: 'SM',
                    text: 'Smooth',
                },
                {
                    key: 'Set side dropdown',
                    value: '',
                    text: 'Set side dropdown',
                },
            ])}
        />
    );
};

export const BeltColorDropdown = ({color, setColor}) => {
    return (
        <Dropdown
            placeholder='Set color'
            value={color}
            onChange={(_, {value}) => setColor(value)}
            fluid
            selection
            options={BlankFixer(color, [
                {key: 'Bison', value: 'Bison', text: 'Bison'},
                {
                    key: 'Mocha',
                    value: 'Mocha',
                    text: 'Mocha',
                },
                {key: 'Brown', value: 'Brown', text: 'Brown'},
                {
                    key: 'Redwood',
                    value: 'Redwood',
                    text: 'Redwood',
                },
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {
                    key: 'Set belt color',
                    value: '',
                    text: 'Set belt color',
                },
            ])}
        />
    );
};

export const ColorDropdown = ({color, setColor}) => {
    return (
        <Dropdown
            placeholder='Set color'
            value={color}
            onChange={(_, {value}) => setColor(value)}
            fluid
            selection
            options={BlankFixer(color, [
                {key: 'Gray', value: 'Gray', text: 'Gray'},
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {key: 'Brown', value: 'Brown', text: 'Brown'},
                {
                    key: 'Mocha',
                    value: 'Mocha',
                    text: 'Mocha',
                },
                {
                    key: 'Redwood',
                    value: 'Redwood',
                    text: 'Redwood',
                },
                {
                    key: 'Bison',
                    value: 'Bison',
                    text: 'Bison',
                },
                {
                    key: 'Shadow Bison',
                    value: 'Shadow Bison',
                    text: 'Shadow Bison'
                },
                {
                    key: 'Canyon Bison',
                    value: 'Canyon Bison',
                    text: 'Canyon Bison'
                },
                {
                    key: 'Mahogany',
                    value: 'Mahogany',
                    text: 'Mahogany',
                },
                {
                    key: 'Set color',
                    value: '',
                    text: 'Set color',
                },
                {
                    key: 'See notes field',
                    value: 'See notes field',
                    text: 'See notes field (do not touch)',
                },
            ])}
        />
    );
};

export const HeightDropdown = ({height, setHeight}) => {
    return (
        <Dropdown
            placeholder='Set height'
            value={height}
            onChange={(_, {value}) => setHeight(value)}
            fluid
            selection
            options={BlankFixer(height, [
                {key: '6"', value: '6"', text: '6"'},
                {
                    key: '8"',
                    value: '8"',
                    text: '8"',
                },
                {key: '10"', value: '10"', text: '10"'},
                {
                    key: '12"',
                    value: '12"',
                    text: '12"',
                },
                {
                    key: '14"',
                    value: '14"',
                    text: '14"',
                },
                {
                    key: '16"',
                    value: '16"',
                    text: '16"',
                },
                {
                    key: '18"',
                    value: '18"',
                    text: '18"',
                },
                {
                    key: '20"',
                    value: '20"',
                    text: '20"',
                },
                {
                    key: 'Select height',
                    value: '',
                    text: 'Select height',
                },
            ])}
        />
    );
};

export const ToeDropdown = ({toe, setToe}) => {
    return (
        <Dropdown
            placeholder='Set toe'
            fluid
            selection
            options={BlankFixer(toe, [
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'Leather Toe Cap',
                    value: 'Leather Toe Cap',
                    text: 'Leather Toe Cap',
                },
                {
                    key: 'Composite',
                    value: 'Composite',
                    text: 'Composite',
                },
                {
                    key: 'Comp + MetGaurd',
                    value: 'Comp + MetGaurd',
                    text: 'Comp + MetGaurd',
                },
                {
                    key: 'Celastic Stiff Toe (No Rating)',
                    value: 'Celastic Stiff Toe (No Rating)',
                    text: 'Celastic Stiff Toe (No Rating)',
                },
                {
                    key: 'Fiberglass Toe',
                    value: 'Fiberglass Toe',
                    text: 'Fiberglass Toe',
                },
                {
                    key: 'Steel Toe',
                    value: 'Steel Toe',
                    text: 'Steel Toe',
                }, 
                {
                    key: 'Leather Toe Cap + Comp Toe',
                    value: 'Leather Toe Cap + Comp Toe',
                    text: 'Leather Toe Cap + Comp Toe',
                },
                {
                    key: 'Select toe type',
                    value: '',
                    text: 'Select toe type',
                },
            ])}
            value={toe}
            onChange={(_, {value}) => setToe(value)}
        />
    );
};

export const LeatherToeCapDropdown = ({leatherToeCap, setLeatherToeCap}) => {
    return (
        <Dropdown
            placeholder='Set Leather Toe Cap rating'
            value={leatherToeCap}
            onChange={(_, {value}) => setLeatherToeCap(value)}
            fluid
            selection
            options={BlankFixer(leatherToeCap, [
                {
                    key: 'Yes',
                    value: 'Yes',
                    text: 'Yes',
                },
                {
                    key: 'No',
                    value: 'No',
                    text: 'No',
                },
                {
                    key: 'Select style',
                    value: '',
                    text: 'Select style',
                },
            ])}
        />
    );
};

export const TimelineDropdown = ({timeline, setTimeline}) => {
    return (
        <Dropdown
            placeholder='Set timeline'
            value={timeline}
            onChange={(_, {value}) => setTimeline(value)}
            fluid
            selection
            options={BlankFixer(timeline, [
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'Expedited',
                    value: 'Expedited',
                    text: 'Expedited',
                },
                {
                    key: 'Select timeline',
                    value: '',
                    text: 'Select timeline',
                },
            ])}
        />
    );
};

export const EHRatingDropdown = ({ehRating, setEHRating}) => {
    return (
        <Dropdown
            placeholder='Set EH rating'
            value={ehRating}
            onChange={(_, {value}) => setEHRating(value)}
            fluid
            selection
            options={BlankFixer(ehRating, [
                {
                    key: 'Yes',
                    value: 'Yes',
                    text: 'Yes',
                },
                {
                    key: 'No',
                    value: 'No',
                    text: 'No',
                },
                {
                    key: 'Select style',
                    value: '',
                    text: 'Select style',
                },
            ])}
        />
    );
};

export const ArchPadDropdown = ({archPad, setArchPad}) => {
    return (
        <Dropdown
            placeholder='Set arch pad'
            value={archPad}
            onChange={(_, {value}) => setArchPad(value)}
            fluid
            selection
            options={BlankFixer(archPad, [
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'Extra High',
                    value: 'Extra High',
                    text: 'Extra High',
                },
            ])}
        />
    );
};

export const StyleDropdown = ({style, setStyle}) => {
    return (
        <Dropdown
            placeholder='Set style'
            value={style}
            onChange={(_, {value}) => setStyle(value)}
            fluid
            selection
            options={BlankFixer(style, [
                //{
                //  key: 'Regular *recommended',
                //  value: 'Regular * recommended', // causing it to print with recommended bit on it
                //  text: 'Regular',
                //},
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'LTT',
                    value: 'LTT',
                    text: 'LTT',
                },
                {
                    key: 'Moc Toe',
                    value: 'Moc Toe',
                    text: 'Moc Toe',
                },
                {
                    key: 'Select style',
                    value: '',
                    text: 'Select style',
                },
            ])}
        />
    );
};

export const LastDropdown = ({last, setLast}) => {
    return (
        <Dropdown
            placeholder='Set last'
            value={last}
            onChange={(_, {value}) => setLast(value)}
            fluid
            selection
            options={BlankFixer(last, [
                {
                    key: '3708',
                    value: '3708',
                    text: '3708',
                },
                {
                    key: '55',
                    value: '55',
                    text: '55',
                },
                {
                    key: '4811',
                    value: '4811',
                    text: '4811',
                },   
            ])}
        />
    );
};

export const FullSideDropdown = ({side, setSide}) => {
    return (
        <Dropdown
            placeholder='Set side'
            value={side}
            onChange={(_, {value}) => setSide(value)}
            fluid
            selection
            options={BlankFixer(side, [
                {
                    key: 'SM',
                    value: 'SM',
                    text: 'Smooth',
                },
                {
                    key: 'F/H',
                    value: 'F/H',
                    text: 'F/H',
                },
                {
                    key: 'RGH',
                    value: 'RGH',
                    text: 'RGH',
                },
                {
                    key: 'See notes field',
                    value: 'See notes field',
                    text: 'See notes field',
                },
                {
                    key: 'Select side',
                    value: '',
                    text: 'Select side',
                },
            ])}
        />
    );
};

export const LogoDropdown = ({logo, setLogo}) => {
    return (
        <Dropdown
            placeholder='Set logo'
            value={logo}
            onChange={(_, {value}) => setLogo(value)}
            fluid
            selection
            options={BlankFixer(logo, [
                {
                    key: 'Flag logo Left Boot Only',
                    value: 'Flag logo Left Boot Only',
                    text: 'Flag logo Left Boot Only',
                },
                {
                    key: 'Custom logo Left Boot Only',
                    value: 'Custom logo Left Boot Only',
                    text: 'Custom logo Left Boot Only',
                },
                {
                    key: 'Select logo',
                    value: '',
                    text: 'Select logo',
                },
            ])}
        />
    );
};

export const RolledTopDropdown = ({rolledTop, setRolledTop}) => {
    return (
        <Dropdown
            placeholder='Set rolled top'
            value={rolledTop}
            onChange={(_, {value}) => setRolledTop(value)}
            fluid
            selection
            options={BlankFixer(rolledTop, [
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {
                    key: 'Brown',
                    value: 'Brown',
                    text: 'Brown',
                },
                {
                    key: 'Gray',
                    value: 'Gray',
                    text: 'Gray',
                }
            ])}
        />
    );
};

export const TopHardwareDropdown = ({topHardware, setTopHardware}) => {
    return (
        <Dropdown
            placeholder='Set top hardware'
            value={topHardware}
            onChange={(_, {value}) => setTopHardware(value)}
            fluid
            selection
            options={BlankFixer(topHardware, [
                {
                    key: 'Eyelet',
                    value: 'Eyelet',
                    text: 'Eyelet',
                },
                {
                    key: 'Speed Hooks',
                    value: 'Speed Hooks',
                    text: 'Speed Hooks',
                },
            ])}
        />
    );
};

export const TongueDropdown = ({tongue, setTongue}) => {
    return (
        <Dropdown
            placeholder='Set tongue'
            value={tongue}
            onChange={(_, {value}) => setTongue(value)}
            fluid
            selection
            options={BlankFixer(tongue, [
                {
                    key: 'Enclosed',
                    value: 'Enclosed',
                    text: 'Enclosed',
                },
                {
                    key: 'Open',
                    value: 'Open',
                    text: 'Open',
                },
            ])}
        />
    );
};

export const TongueRepairDropdown = ({tongues, setTongues}) => {
    return (
        <Dropdown
            placeholder='Set tongue'
            value={tongues}
            onChange={(_, {value}) => setTongues(value)}
            fluid
            selection
            options={BlankFixer(tongues, [
                {
                    key: 'Refurbish (patch minor damage/small holes)',
                    value: 'Refurbish (patch minor damage/small holes)',
                    text: 'Refurbish (patch minor damage/small holes)',
                },
                {
                    key: 'Replace Entire Tongue (major damage/large holes)',
                    value: 'Replace Entire Tongue (major damage/large holes)',
                    text: 'Replace Entire Tongue (major damage/large holes)',
                },
            ])}
        />
    );
};

export const MidsoleDropdown = ({midSole, setMidSole}) => {
    return (
        <Dropdown
            placeholder='Set midsole'
            value={midSole}
            onChange={(_, {value}) => setMidSole(value)}
            fluid
            selection
            options={BlankFixer(midSole, [
                {
                    key: 'Standard - 12 Iron',
                    value: 'Standard - 12 Iron',
                    text: 'Standard - 12 Iron',
                },
                {
                    key: 'Flexy - 12 Iron',
                    value: 'Flexy - 12 Iron',
                    text: 'Flexy - 12 Iron',
                },
                {
                    key: 'Standard - 9 Iron',
                    value: 'Standard - 9 Iron',
                    text: 'Standard - 9 Iron',
                },
                {
                    key: 'Flexy - 9 Iron',
                    value: 'Flexy - 9 Iron',
                    text: 'Flexy - 9 Iron',
                },
                {
                    key: 'Double - 9 Iron',
                    value: 'Double - 9 Iron',
                    text: 'Double - 9 Iron',
                },
                {
                    key: 'Double - 12 Iron',
                    value: 'Double - 12 Iron',
                    text: 'Double - 12 Iron',
                },
                {
                    key: 'Lineman',
                    value: 'Lineman',
                    text: 'Lineman',
                },               
            ])}
        />
    );
};

export const SecondarySoleDropdown = ({secondarySole, setSecondarySole}) => {
    return (
        <Dropdown
            placeholder='Set secondary sole'
            value={secondarySole}
            onChange={(_, {value}) => setSecondarySole(value)}
            fluid
            selection
            options={BlankFixer(secondarySole, [
                {
                    key: 'Kletterlift',
                    value: 'Kletterlift',
                    text: 'Kletterlift',
                },
                {
                    key: 'Sierra',
                    value: 'Sierra',
                    text: 'Sierra',
                },
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                
            ])}
        />
    );
};

export const InnerShankDropdown = ({innerShank, setInnerShank}) => {
    return (
        <Dropdown
            placeholder='Set inner shank'
            value={innerShank}
            onChange={(_, {value}) => setInnerShank(value)}
            fluid
            selection
            options={BlankFixer(innerShank, [
                {
                    key: 'Leather',
                    value: 'Leather',
                    text: 'Leather',
                },
                {
                    key: 'Steel',
                    value: 'Steel',
                    text: 'Steel',
                },
                {
                    key: 'Leather & Steel',
                    value: 'Leather & Steel',
                    text: 'Leather & Steel',
                },
            ])}
        />
    );
};

export const WedgeDropdown = ({wedge, setWedge}) => {
    return (
        <Dropdown
            placeholder='Set wedge'
            value={wedge}
            onChange={(_, {value}) => setWedge(value)}
            fluid
            selection
            options={BlankFixer(wedge, [
                {
                    key: '1/8" outer"',
                    value: '1/8" outer',
                    text: '1/8" outer',
                },
                {
                    key: '1/8" inner',
                    value: '1/8" inner',
                    text: '1/8" inner',
                },
                /* {
                   key: '1/4 wedge outside',
                   value: '1/4 wedge outside',
                   text: '1/4 wedge outside',
                 },
                 {
                   key: '1/4 wedge inside',
                   value: '1/4 wedge inside',
                   text: '1/4 wedge inside',
                 },*/
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
            ])}
        />
    );
};

export const EdgeStainColorDropdown = ({edgeStain, setEdgeStain}) => {
    return (
        <Dropdown
            placeholder='Set edge stain'
            value={edgeStain}
            onChange={(_, {value}) => setEdgeStain(value)}
            fluid
            selection
            options={BlankFixer(edgeStain, [
                {
                    key: 'Standard',
                    value: 'Standard',
                    text: 'Standard',
                },
                {
                    key: 'Orange',
                    value: 'Orange',
                    text: 'Orange',
                },
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {
                    key: 'Brown',
                    value: 'Brown',
                    text: 'Brown',
                },
                {
                    key: 'Natural',
                    value: 'Natural',
                    text: 'Natural',
                },
            ])}
        />
    );
};

export const EyeletsDropdown = ({eyelets, setEyelets}) => {
    return (
        <Dropdown
            placeholder='Set eyelets'
            value={eyelets}
            onChange={(_, {value}) => setEyelets(value)}
            fluid
            selection
            options={BlankFixer(eyelets, [
                {
                    key: '4',
                    value: '4',
                    text: '4',
                },
                {
                    key: '5',
                    value: '5',
                    text: '5',
                },
                {
                    key: '6',
                    value: '6',
                    text: '6',
                },
                {
                    key: '7',
                    value: '7',
                    text: '7',
                },
                {
                    key: '8',
                    value: '8',
                    text: '8',
                },
                {
                    key: 'All',
                    value: 'All',
                    text: 'All',
                },
            ])}
        />
    );
};

export const LiningDropdown = ({lining, setLining}) => {
    return (
        <Dropdown
            placeholder='Set lining'
            value={lining}
            onChange={(_, {value}) => setLining(value)}
            fluid
            selection
            options={BlankFixer(lining, [
                {
                    key: 'Thinsulate',
                    value: 'Thinsulate',
                    text: 'Thinsulate',
                },
                {
                    key: 'Thinsulate - below zero',
                    value: 'Thinsulate - below zero',
                    text: 'Thinsulate - below zero',
                },
                {
                    key: 'No insulation',
                    value: '',
                    text: 'No insulation',
                },
            ])}
        />
    );
};

export const BlackEditionLiningDropdown = ({lining, setLining}) => {
    return (
        <Dropdown
            placeholder='Set lining'
            value={lining}
            onChange={(_, {value}) => setLining(value)}
            fluid
            selection
            options={BlankFixer(lining, [
                {
                    key: 'Thinsulate',
                    value: 'Thinsulate',
                    text: 'Thinsulate',
                },
                {
                    key: 'Thinsulate - below zero',
                    value: 'Thinsulate - below zero',
                    text: 'Thinsulate - below zero',
                },
                {
                    key: 'Elk Lining',
                    value: 'Elk Lining',
                    text: 'Elk Lining',
                },
                {
                    key: 'Fur',
                    value: 'Fur',
                    text: 'Fur',
                },
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
            ])}
        />
    );
};

export const HardwareDropdown = ({hardware, setHardware}) => {
    return (
        <Dropdown
            placeholder='Set hardware'
            value={hardware}
            onChange={(_, {value}) => setHardware(value)}
            fluid
            selection
            options={BlankFixer(hardware, [
                {
                    key: 'Brass',
                    value: 'Brass',
                    text: 'Brass',
                },
                {
                    key: 'Antique Brass',
                    value: 'Antique Brass',
                    text: 'Antique Brass',
                },
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {
                    key: 'Nickel',
                    value: 'Nickel',
                    text: 'Nickel',
                },
                {
                    key: 'Select hardware',
                    value: '',
                    text: 'Select hardware',
                },
            ])}
        />
    );
};

export const BeltHardwareDropdown = ({hardware, setHardware}) => {
    return (
        <Dropdown
            placeholder='Set hardware'
            value={hardware}
            onChange={(_, {value}) => setHardware(value)}
            fluid
            selection
            options={BlankFixer(hardware, [
                {
                    key: 'Antique Brass',
                    value: 'Antique Brass',
                    text: 'Antique Brass',
                },
                {
                    key: 'Nickel',
                    value: 'Nickel',
                    text: 'Nickel',
                },
                {
                    key: 'Select belt hardware',
                    value: '',
                    text: 'Select belt hardware',
                },
            ])}
        />
    );
};
//not used by ticket
export const SideSheathDropdown = ({setDropdownSideSheathText}) => {
    return (
        <Dropdown
            placeholder='Set side sheath'
            onChange={(_, {value}) => setDropdownSideSheathText(value)}
            fluid
            selection
            options={[
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
                {
                    key: 'Outside Right Leg 1.5" wide X 3" tall',
                    value: 'Outside Right Leg 1.5" wide X 3" tall',
                    text: 'Outside Right Leg 1.5" wide X 3" tall',
                },
                {
                    key: 'Outside Right Leg 2" wide X 3" tall',
                    value: 'Outside Right Leg 2" wide X 3" tall',
                    text: 'Outside Right Leg 2" wide X 3" tall',
                },
                {
                    key: 'Outside Right Leg 2" wide X 5" tall',
                    value: 'Outside Right Leg 2" wide X 5" tall',
                    text: 'Outside Right Leg 2" wide X 5" tall',
                },
                {
                    key: 'Inside Right Leg 1.5" wide X 3" tall',
                    value: 'Inside Right Leg 1.5" wide X 3" tall',
                    text: 'Inside Right Leg 1.5" wide X 3" tall',
                },
                {
                    key: 'Inside Right Leg 2" wide X 3" tall',
                    value: 'Inside Right Leg 2" wide X 3" tall',
                    text: 'Inside Right Leg 2" wide X 3" tall',
                },
                {
                    key: 'Inside Right Leg 2" wide X 5" tall',
                    value: 'Inside Right Leg 2" wide X 5" tall',
                    text: 'Inside Right Leg 2" wide X 5" tall',
                },
                {
                    key: 'Outside Left Leg 1.5" wide X 3" tall',
                    value: 'Outside Left Leg 1.5" wide X 3" tall',
                    text: 'Outside Left Leg 1.5" wide X 3" tall',
                },
                {
                    key: 'Outside Left Leg 2" wide X 3" tall',
                    value: 'Outside Left Leg 2" wide X 3" tall',
                    text: 'Outside Left Leg 2" wide X 3" tall',
                },
                {
                    key: 'Outside Left Leg 2" wide X 5" tall',
                    value: 'Outside Left Leg 2" wide X 5" tall',
                    text: 'Outside Left Leg 2" wide X 5" tall',
                },
                {
                    key: 'Inside Left Leg 1.5" wide X 3" tall',
                    value: 'Inside Left Leg 1.5" wide X 3" tall',
                    text: 'Inside Left Leg 1.5" wide X 3" tall',
                },
                {
                    key: 'Inside Left Leg 2" wide X 3" tall',
                    value: 'Inside Left Leg 2" wide X 3" tall',
                    text: 'Inside Left Leg 2" wide X 3" tall',
                },
                {
                    key: 'Inside Left Leg 2" wide X 5" tall',
                    value: 'Inside Left Leg 2" wide X 5" tall',
                    text: 'Inside Left Leg 2" wide X 5" tall',
                },
                {
                    key: 'Select sheath',
                    value: '',
                    text: 'Select sheath',
                },
            ]}
        />
    );
};

export const LeftSideSheathDropdown = ({
                                           dropdownText,
                                           setDropdownSideSheathText,
                                       }) => {
    return (
        <Dropdown
            placeholder='Set left side sheath'
            onChange={(_, {value}) => setDropdownSideSheathText(value)}
            fluid
            value={dropdownText}
            selection
            options={BlankFixer(dropdownText, [
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
                {
                    key: 'Left Leg, Left Side 1.5" wide X 3" tall',
                    value: 'Left Leg, Left Side 1.5" wide X 3" tall',
                    text: 'Left Leg, Left Side 1.5" wide X 3" tall',
                },
                {
                    key: 'Left Leg, Left Side 2" wide X 3" tall',
                    value: 'Left Leg, Left Side 2" wide X 3" tall',
                    text: 'Left Leg, Left Side 2" wide X 3" tall',
                },
                {
                    key: 'Left Leg, Left Side 2" wide X 5" tall',
                    value: 'Left Leg, Left Side 2" wide X 5" tall',
                    text: 'Left Leg, Left Side 2" wide X 5" tall',
                },
                {
                    key: 'Left Leg, Right Side 1.5" wide X 3" tall',
                    value: 'Left Leg, Right Side 1.5" wide X 3" tall',
                    text: 'Left Leg, Right Side 1.5" wide X 3" tall',
                },
                {
                    key: 'Left Leg, Right Side 2" wide X 3" tall',
                    value: 'Left Leg, Right Side 2" wide X 3" tall',
                    text: 'Left Leg, Right Side 2" wide X 3" tall',
                },
                {
                    key: 'Left Leg, Right Side 2" wide X 5" tall',
                    value: 'Left Leg, Right Side 2" wide X 5" tall',
                    text: 'Left Leg, Right Side 2" wide X 5" tall',
                },
                {
                    key: 'Select sheath',
                    value: '',
                    text: 'Select sheath',
                },
            ])}
            //"Left Leg, Left Side 2" wide X 5" tall"
        />
    );
};

export const RightSideSheathDropdown = ({
                                            dropdownText,
                                            setDropdownSideSheathText,
                                        }) => {
    return (
        <Dropdown
            placeholder='Set right side sheath'
            onChange={(_, {value}) => setDropdownSideSheathText(value)}
            value={dropdownText}
            fluid
            selection
            options={BlankFixer(dropdownText, [
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
                {
                    key: 'Right Leg, Right Side 1.5" wide X 3" tall',
                    value: 'Right Leg, Right Side 1.5" wide X 3" tall',
                    text: 'Right Leg, Right Side 1.5" wide X 3" tall',
                },
                {
                    key: 'Right Leg, Right Side 2" wide X 3" tall',
                    value: 'Right Leg, Right Side 2" wide X 3" tall',
                    text: 'Right Leg, Right Side 2" wide X 3" tall',
                },
                {
                    key: 'Right Leg, Right Side 2" wide X 5" tall',
                    value: 'Right Leg, Right Side 2" wide X 5" tall',
                    text: 'Right Leg, Right Side 2" wide X 5" tall',
                },
                {
                    key: 'Right Leg, Left Side 1.5" wide X 3" tall',
                    value: 'Right Leg, Left Side 1.5" wide X 3" tall',
                    text: 'Right Leg, Left Side 1.5" wide X 3" tall',
                },
                {
                    key: 'Right Leg, Left Side 2" wide X 3" tall',
                    value: 'Right Leg, Left Side 2" wide X 3" tall',
                    text: 'Right Leg, Left Side 2" wide X 3" tall',
                },
                {
                    key: 'Right Leg, Left Side 2" wide X 5" tall',
                    value: 'Right Leg, Left Side 2" wide X 5" tall',
                    text: 'Right Leg, Left Side 2" wide X 5" tall',
                },
                {
                    key: 'Select sheath',
                    value: '',
                    text: 'Select sheath',
                },
            ])}
        />
    );
};

export const PrinterDropdown = ({printer, setPrinter}) => {
    return (
        <Dropdown
            placeholder='Override printer'
            value={printer}
            onChange={(_, {value}) => setPrinter(value)}
            fluid
            selection
            //options={dropdownOptions}
            options={BlankFixer(printer, [
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
                {
                    key: 'Stock',
                    value: 'Stock',
                    text: 'Stock',
                },
                {
                    key: 'NotStocked',
                    value: 'NotStocked',
                    text: 'NotStocked',
                },
                {
                    key: 'Custom',
                    value: 'Custom',
                    text: 'Custom',
                },
                {
                    key: 'Rebuild',
                    value: 'Rebuild',
                    text: 'Rebuild',
                },
                {
                    key: 'Tryon',
                    value: 'Tryon',
                    text: 'Try On',
                },
                {
                    key: 'Warranty',
                    value: 'Warranty',
                    text: 'Warranty',
                },
                {
                    key: 'ToStock',
                    value: 'ToStock',
                    text: 'To Stock'
                },
                {
                    key: 'QC',
                    value: 'QC',
                    text: 'QC'
                }
            ])}
        />
    );
};

export const InStockDropdown = ({inStock, setInStock}) => {
    return (
        <Dropdown
            placeholder='In Stock?'
            value={inStock}
            onChange={(_, {value}) => setInStock(value)}
            fluid
            selection
            options={BlankFixer(inStock, [
                {
                    key: 'Yes',
                    value: true,
                    text: 'Yes',
                },
                {
                    key: 'No',
                    value: false,
                    text: 'No',
                },
            ])}
        />
    );
};

export const OtherGoodsDropdown = ({otherGoods, setOtherGoods}) => {
    return (
        <Dropdown
            placeholder='Select status of other goods'
            value={otherGoods}
            onChange={(_, {value}) => setOtherGoods(value)}
            fluid
            selection
            options={BlankFixer(otherGoods, [
                {
                    key: 'Yes',
                    value: 'Yes',
                    text: 'Yes',
                },
                {
                    key: 'No',
                    value: '',
                    text: 'No',
                },
            ])}
        />
    );
};

export const StitchDropdown = ({stitch, setStitch}) => {
    return (
        <Dropdown
            placeholder='Select stitch'
            value={stitch}
            onChange={(_, {value}) => setStitch(value)}
            fluid
            selection
            options={BlankFixer(stitch, [
                {
                    key: 'Technora',
                    value: 'Technora',
                    text: 'Technora',
                },
                {
                    key: 'Beige',
                    value: 'Beige',
                    text: 'Beige',
                },
                {
                    key: 'Select Stitch',
                    value: '',
                    text: 'Select Stitch',
                },
            ])}
        />
    );
};

export const ShankDropdown = ({shank, setShank}) => {
    return (
        <Dropdown
            placeholder='Select shank'
            value={shank}
            onChange={(_, {value}) => setShank(value)}
            fluid
            selection
            options={BlankFixer(shank, [
                {
                    key: 'Lineman Shank',
                    value: 'Lineman Shank',
                    text: 'Lineman Shank',
                },
                {
                    key: 'Thin Leather',
                    value: 'Thin Leather',
                    text: 'Thin Leather',
                },
                {
                    key: 'Leather',
                    value: 'Leather',
                    text: 'Leather',
                },
                {
                    key: 'Reinforced Shank',
                    value: 'Reinforced Shank',
                    text: 'Reinforced Shank',
                },
                {
                    key: 'Select Shank',
                    value: '',
                    text: 'Select Shank',
                },
            ])}
        />
    );
};

export const PatchDropdown = ({patch, setPatch}) => {
    return (
        <Dropdown
            placeholder='Select patch'
            value={patch}
            onChange={(_, {value}) => setPatch(value)}
            fluid
            selection
            options={BlankFixer(patch, [
                {
                    key: 'Outside',
                    value: 'Outside',
                    text: 'Lineman Outside',
                },
                {
                    key: 'Inside',
                    value: 'Inside',
                    text: 'Lineman Inside',
                },
                {
                    key: 'Both',
                    value: 'Both',
                    text: 'Lineman Both',
                },
                {
                    key: 'Select Patch',
                    value: '',
                    text: 'Select Patch',
                },
            ])}
        />
    );
};

export const ArchDropdown = ({arch, setArch}) => {
    return (
        <Dropdown
            placeholder='Select arch'
            value={arch}
            onChange={(_, {value}) => setArch(value)}
            fluid
            selection
            options={BlankFixer(arch, [
                {
                    key: 'Standard',
                    value: 'Standard',
                    text: 'Standard',
                },

                {
                    key: 'NAJS',
                    value: 'NAJS',
                    text: 'NAJS',
                },
                {
                    key: 'Select arch',
                    value: '',
                    text: 'Select arch',
                },
            ])}
        />
    );
};

export const SoleDropdown = ({sole, setSole}) => {
    return (
        <Dropdown
            placeholder='Select sole'
            value={sole}
            onChange={(_, {value}) => setSole(value)}
            fluid
            selection
            options={BlankFixer(sole, [
                {
                    key: '132 RedX',
                    value: '132 RedX',
                    text: '132 RedX',
                },
                {
                    key: '132 Honey',
                    value: '132 Honey',
                    text: '132 Honey',
                },
                {
                    key: '430 mini Vibram',
                    value: '430 mini Vibram',
                    text: '430 mini Vibram',
                },
                {
                    key: '700 V-Bar Vibram',
                    value: '700 V-Bar Vibram',
                    text: '700 V-Bar Vibram',
                },
                {
                    key: 'JK Branded',
                    value: 'JK Branded',
                    text: 'JK Branded',
                },
                {
                    key: '100 WhiteX Lugsole',
                    value: '100 WhiteX Lugsole',
                    text: '100 WhiteX Lugsole',
                },
                {
                    key: '100 Honey Lugsole',
                    value: '100 Honey Lugsole',
                    text: '100 Honey Lugsole',
                },
                {
                    key: '100 RedX Lugsole',
                    value: '100 RedX Lugsole',
                    text: '100 RedX Lugsole',
                },
                {
                    key: '4014 Black Christy',
                    value: '4014 Black Christy',
                    text: '4014 Black Christy',
                },
                {
                    key: '4014 White Christy',
                    value: '4014 White Christy',
                    text: '4014 White Christy',
                },
                {
                    key: '100 All-terrain',
                    value: '100 All-terrain',
                    text: '100 All-terrain',
                },
                {
                    key: 'Cork Caulk sole',
                    value: 'Cork Caulk sole',
                    text: 'Cork Caulk sole',
                },
                {
                    key: 'Kletterlift',
                    value: 'Kletterlift',
                    text: 'Kletterlift',
                },
                {
                    key: 'Sierra',
                    value: 'Sierra',
                    text: 'Sierra',
                },
                {
                    key: 'Asphalt',
                    value: 'Asphalt',
                    text: 'Asphalt',
                },
            ])}
        />
    );
};

export const BootModelsDropdown = ({bootModel, setBootModel}) => {
    return (
        <Dropdown
            placeholder='Select boot model'
            value={bootModel}
            onChange={(_, {value}) => setBootModel(value)}
            fluid
            selection
            options={BlankFixer(bootModel, [
                {
                    key: '300',
                    value: '300',
                    text: '300',
                },
                {
                    key: '300 (Safety Toe)',
                    value: '300 (Safety Toe)',
                    text: '300 (Safety Toe)',
                },
                {
                    key: '300X',
                    value: '300X',
                    text: '300X',
                },
                {
                    key: '300X (Safety Toe)',
                    value: '300X (Safety Toe)',
                    text: '300X (Safety Toe)',
                },
                {
                    key: 'Arctic No. 1',
                    value: 'Arctic No. 1',
                    text: 'Arctic No. 1',
                },
                {
                    key: 'Arctic No. 2',
                    value: 'Arctic No. 2',
                    text: 'Arctic No. 2',
                },
                {
                    key: 'Climber',
                    value: 'Climber',
                    text: 'Climber',
                },
                {
                    key: 'Climber (Safety Toe)',
                    value: 'Climber (Safety Toe)',
                    text: 'Climber (Safety Toe)',
                },
                {
                    key: 'Fire Inlander',
                    value: 'Fire Inlander',
                    text: 'Fire Inlander',
                },
                {
                    key: 'Fire Inlander LTT',
                    value: 'Fire Inlander LTT',
                    text: 'Fire Inlander LTT',
                },
                {
                    key: 'Fire Inlander S',
                    value: 'Fire Inlander S',
                    text: 'Fire Inlander S',
                },
                {
                    key: 'Forefront',
                    value: 'Forefront',
                    text: 'Forefront',
                },
                {
                    key: 'Forefront (Safety Toe)',
                    value: 'Forefront (Safety Toe)',
                    text: 'Forefront (Safety Toe)',
                },
                {
                    key: 'Forefront 8"',
                    value: 'Forefront 8"',
                    text: 'Forefront 8"',
                },
                {
                    key: 'Forefront 8" (Safety Toe)',
                    value: 'Forefront 8" (Safety Toe)',
                    text: 'Forefront 8" (Safety Toe)',
                },
                {
                    key: 'Harvester',
                    value: 'Harvester',
                    text: 'Harvester',
                },
                {
                    key: 'Honey Fire',
                    value: 'Honey Fire',
                    text: 'Honey Fire',
                },
                {
                    key: 'O.T.',
                    value: 'O.T.',
                    text: 'O.T.',
                },
                {
                    key: 'O.T. (Safety Toe)',
                    value: 'O.T. (Safety Toe)',
                    text: 'O.T. (Safety Toe)',
                },
                {
                    key: 'O.T. 6"',
                    value: 'O.T. 6"',
                    text: 'O.T. 6"',
                },
                {
                    key: 'O.T. 6" (Safety Toe)',
                    value: 'O.T. 6" (Safety Toe)',
                    text: 'O.T. 6" (Safety Toe)',
                },
                {
                    key: 'O.T. Pro',
                    value: 'O.T. Pro',
                    text: 'O.T. Pro',
                },
                {
                    key: 'O.T. Pro (Safety Toe)',
                    value: 'O.T. Pro (Safety Toe)',
                    text: 'O.T. Pro (Safety Toe)',
                },
                {
                    key: 'Superduty',
                    value: 'Superduty',
                    text: 'Superduty',
                },
                {
                    key: 'Superduty (Safety Toe)',
                    value: 'Superduty (Safety Toe)',
                    text: 'Superduty (Safety Toe)',
                },
                {
                    key: 'Superduty Honey (Safety Toe)',
                    value: 'Superduty Honey (Safety Toe)',
                    text: 'Superduty Honey (Safety Toe)',
                },
                {
                    key: 'Superduty S',
                    value: 'Superduty S',
                    text: 'Superduty S',
                },
                {
                    key: 'Superduty S (Safety Toe)',
                    value: 'Superduty S (Safety Toe)',
                    text: 'Superduty S (Safety Toe)',
                },
            ])}
        />
    );
};

export const BeltModelsDropdown = ({belt, setBelt}) => {
    return (
        <Dropdown
            placeholder='Select belt model'
            value={belt}
            onChange={(_, {value}) => setBelt(value)}
            fluid
            selection
            options={BlankFixer(belt, [
                {
                    key: 'Smooth Black Leather Belt',
                    value: 'Smooth Black Leather Belt',
                    text: 'Smooth Black Leather Belt',
                },
                {
                    key: 'Rough Black Leather Belt',
                    value: 'Rough Black Leather Belt',
                    text: 'Rough Black Leather Belt',
                },
                {
                    key: 'Smooth Brown Leather Belt',
                    value: 'Smooth Brown Leather Belt',
                    text: 'Smooth Brown Leather Belt',
                },
                {
                    key: 'Rough Brown Leather Belt',
                    value: 'Rough Brown Leather Belt',
                    text: 'Rough Brown Leather Belt',
                },
                {
                    key: 'Smooth Redwood Leather Belt',
                    value: 'Smooth Redwood Leather Belt',
                    text: 'Smooth Redwood Leather Belt',
                },
                {
                    key: 'Rough Redwood Leather Belt',
                    value: 'Rough Redwood Leather Belt',
                    text: 'Rough Redwood Leather Belt',
                },
                {
                    key: 'Smooth Mocha Leather Belt',
                    value: 'Smooth Mocha Leather Belt',
                    text: 'Smooth Mocha Leather Belt',
                },
                {
                    key: 'Rough Mocha Leather Belt',
                    value: 'Rough Mocha Leather Belt',
                    text: 'Rough Mocha Leather Belt',
                },
                {
                    key: 'Bison Leather Belt',
                    value: 'Bison Leather Belt',
                    text: 'Bison Leather Belt',
                },
                {
                    key: 'Rough Gray Leather Belt',
                    value: 'Rough Gray Leather Belt',
                    text: 'Rough Gray Leather Belt',
                },
                {
                    key: 'Smooth Gray Leather Belt',
                    value: 'Smooth Gray Leather Belt',
                    text: 'Smooth Gray Leather Belt',
                },
            ])}
        />
    );
};


export const PrintReasonDropdown = ({ printReason, setPrintReason }) => {
    const [isOther, setIsOther] = useState(false);

    const handleDropdownChange = (_, { value }) => {
        if (value === 'Other') {
            setIsOther(true);
            setPrintReason(''); // Clear the stitch value when "Other" is selected
        } else {
            setIsOther(false);
            setPrintReason(value);
        }
    };

    return (
        <>
            <Dropdown
                placeholder="Select print reason"
                value={isOther ? 'Other' : printReason}
                onChange={handleDropdownChange}
                fluid
                selection
                options={[
                    {
                        key: 'Select Print Reason',
                        value: '',
                        text: 'Select Print Reason',
                    },
                    {
                        key: 'Remake',
                        value: 'Remake',
                        text: 'Remake',
                    },
                    {
                        key: 'Warranty',
                        value: 'Warranty',
                        text: 'Warranty',
                    },
                    {
                        key: 'Escalation - Social Media',
                        value: 'Escalation - Social Media',
                        text: 'Escalation - Social Media',
                    },
                    {
                        key: 'Escalation - Direct Communication',
                        value: 'Escalation - Direct Communication',
                        text: 'Escalation - Direct Communication',
                    },
                    {
                        key: 'Escalation - Internal Discovery',
                        value: 'Escalation - Internal Discovery',
                        text: 'Escalation - Internal Discovery',
                    },
                    {
                        key: 'Greg Said So',
                        value: 'Greg Said So',
                        text: 'Greg Said So',
                    },
                    {
                        key: 'Other',
                        value: 'Other',
                        text: 'Other',
                    },
                ]}
            />
            {isOther && (
                <Input
                    placeholder="Enter custom print reason"
                    value={printReason}
                    onChange={(e) => setPrintReason(e.target.value)}
                    fluid
                    style={{ marginTop: '10px' }}
                />
            )}
        </>
    );
};


const BlankFixer = (sentIn, dropDownOptions) => {

    if (sentIn == "")
        return dropDownOptions;
    if (sentIn == sentIn == "undefined")
        return dropDownOptions;
    let inDropdown = false;
    for (let x = 0; x < dropDownOptions.length; x++) {
        if (dropDownOptions[x].key == sentIn) {
            inDropdown = true;
        }
    }
    if (!inDropdown) {
        let helper = dropDownOptions.concat({
            key: sentIn,
            value: sentIn,
            text: "!!(NIOD) " + sentIn,
        })
        return helper;
    }
    return dropDownOptions;
};